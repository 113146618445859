$grid-columns: 16;

/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/scss/bootstrap';
@import 'constants.scss';

body {
    min-width: 320px;
}

button {
    border: none;
    outline: none;
}
button:focus {
    //border: none;
    outline: none;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.letterSpacing-064 {
    letter-spacing: 0.64px;
}
.letterSpacing-08 {
    letter-spacing: 0.8px;
}
.letterSpacing-073 {
    letter-spacing: 0.73px;
}
.letterSpacing-096 {
    letter-spacing: 0.96px;
}

.adelle-sans-8 {
    font-size: 8px;
    line-height: 2.083;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-10 {
    font-size: 10px;
    line-height: 1.7;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-11 {
    font-size: 11px;
    line-height: 2.273;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-12 {
    font-size: 12px;
    line-height: 2.083;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-14 {
    font-size: 14px;
    line-height: 1.571;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-17 {
    font-size: 17px;
    line-height: 1.471;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

@media screen and (max-width: 959px) {
    .adelle-sans-17 {
        font-size: 14px;
    }
}

.adelle-sans-20 {
    font-size: 20px;
    line-height: 1.4;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-24 {
    font-size: 24px;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-25 {
    font-size: 25px;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-sans-31 {
    font-size: 31px;
    font-family: adelle-sans, sans-serif;
    font-style: normal;
}

.adelle-pe-17 {
    font-size: 17px;
    line-height: 1.588;
    font-family: adelle, sans-serif;
    font-style: normal;
}

.adelle-pe-12 {
    font-size: 12px;
    line-height: 2.083;
    font-family: adelle, sans-serif;
    font-style: normal;
}

.adelle-pe-20 {
    font-size: 20px;
    line-height: 1.4;
    font-family: adelle, sans-serif;
    font-style: normal;
}

.adelle-pe-25 {
    font-size: 25px;
    line-height: 1.588;
    font-family: adelle, sans-serif;
    font-style: normal;
}

.adelle-pe-31 {
    font-size: 31px;
    line-height: 1.323;
    font-family: adelle, sans-serif;
    font-style: normal;
}

@media screen and (max-width: 959px) {
    .adelle-pe-31 {
        font-size: 19px;
    }
}

[class^='adelle'].sans {
    font-family: adelle, sans;
}

.lh-1 {
    line-height: 1 !important;
}

.gray-background {
    background-color: #f5f5f5;
}
.dark-gray {
    color: #454f54;
}

.link,
a:not([href]) {
    color: #454f54;
}

a:hover {
    color: #454f54;
    text-decoration: none;
}

.dark-gray-background {
    background-color: #454f54;
}
.light-gray {
    color: #6a7276 !important;
}

.light-green-background {
    background-color: #5ec899;
}
.white-background {
    background-color: white;
}

.blue-background {
    background-color: #0071b0;
}
.blue-color {
    color: #0071b0;
}
.light-green-color {
    color: #5ec899;
}
.green-color {
    color: #5dae56;
}
.black-color {
    color: black;
}

.white-color {
    color: white;
}
.red-color {
    color: #d9230d;
}
.dark-red-color {
    color: #c44734;
}
.egg-bg {
    background-color: #fffef7;
}

.dark-egg-bg {
    background-color: #f9f8eb;
}

.c-cursor-pointer {
    cursor: pointer;
}

// FOR SIDENAV BUT CAN REPLACE FONTS ABOVE AT SOME POINT
.adelle-pe {
    font-family: adelle, sans-serif;
}

.adelle-sans {
    font-family: adelle-sans, sans-serif;
}

.adelle-sans,
.adelle-pe {
    &.size-14 {
        font-size: 14px;
        font-weight: 300;
    }
    &.size-17 {
        font-size: 17px;
        font-weight: 300;
    }
    &.size-20 {
        font-size: 20px;
        font-weight: 300;
    }
    &.size-24 {
        font-size: 24px;
        font-weight: 300;
    }
}

.custom-modal div .modal-content {
    border-radius: 0;
}

.todo {
    color: red;
}

.modal-backdrop.fade.show {
    background-color: #2c383d !important;
    opacity: 0.8 !important;
}

.card {
    border-bottom: none;
    border-top: 1px solid #dbdbdb;
}

.card:last-child {
    border-bottom: 1px solid #dbdbdb;
    border-top: 1px solid #dbdbdb;
}

// remove bottom border from header (collapsed accordion)
.card-header {
    border-bottom: none;
}
//remove weird box on mouseover (accordion)
.card-header .btn {
    box-shadow: none;
}
//remove mouseover underline
.card-header .btn-link:hover {
    text-decoration: none;
}
.card-header .btn-link {
    text-decoration: none;
}

input[type='checkbox'] {
    -webkit-appearance: checkbox;
}
